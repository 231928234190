$darkest: #2c3531;
$dark: #116466;
$medium: #d9b08c;
$light: #ffcb9a;
$lightest: #d1e8e2;

// $darkest: #8d8741;
// $dark: #659dbd;
// $medium: #daad86;
// $light: #bc986a;
// $lightest: #fbeec1;

// $darkest: #05386b;
// $dark: #379683;
// $medium: #5cdb95;
// $light: #8ee4af;
// $lightest: #edf5e1;

// $darkest: #fc4445;
// $dark: #3feee6;
// $medium: #55bcc9;
// $light: #97caef;
// $lightest: #cafafe;

// $darkest: #2e1114;
// $dark: #501b1d;
// $medium: #64485c;
// $light: #83677b;
// $lightest: #adadad;

// $darkest: #222629;
// $dark: #86c232;
// $medium: #61892f;
// $light: #474b4f;
// $lightest: #6b6e70;

// $darkest: #31708e; //31708e
// $dark: #687864;
// $medium: #5085a5;
// $light: #8fc1e3;
// $lightest: #f7f9fb;