.switchBody{
    height: 100%;
    width: 100%;
    border-radius: 1000px;
    background-color: grey;
    cursor: pointer;
    transition: background-color .4s;
    &.toggledOn{
        flex-direction: row-reverse;
        background-color: darkcyan;
    }
    position: relative;
}

.switch{
    margin: 2px 0px;
    height: calc(100% - 4px);
    aspect-ratio: 1/1;
    border-radius: 50%;
    background-color: lightgrey;
    position: absolute;
    left: 2px;
    transition: all .4s;
    

    &.toggledOn{
        left: calc(100% - 2px);
        transform: translateX(-100%);

    }
}