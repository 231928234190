@use "./styles/_constants.scss";
@use "./styles/themes/theme1.scss";

/* Let's get this party started */
::-webkit-scrollbar {
	width: constants.$scrollbarWidth;
}

/* Track */
::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: theme1.$darkest;
}

/* Handle */
::-webkit-scrollbar-thumb {
	-webkit-border-radius: 10px;
	border-radius: 10px;
	background: rgba(100, 100, 100, 0.8);
	opacity: .2;
	background: theme1.$light;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
	background: theme1.$dark;
	opacity: .5;
}

body {
	margin: 0px;
	background-color: theme1.$lightest;

}

#root {
	display: grid;
	grid-template-areas:
		"header"
		"body";
	grid-template-rows: 50px 1fr;
	color: theme1.$darkest;

}

.gutter {
	background-color: lightgrey;
	display: flex;

	&.left {
		grid-area: left;
	}

	&.right {
		grid-area: right;
	}
}

h1 {
	font-family: constants.$fontOne;
	font-size: 64px;
	margin: 0px;
	line-height: 100px;
}

.titleBanner {
	background-color: theme1.$dark;
	color: theme1.$light;
	clip-path: polygon(30px 0%, 100% 0%, 100% calc(100% - 70px), calc(100% - 70px) 100%, 0% 100%, 0% 30px);
	margin-left: -20px;
	padding-left: 20px;
	padding-right: 40px;
	width: calc(100% + 40px);
	box-sizing: border-box;

	box-shadow: 20px 20px 20px 20px theme1.$dark;
}

h2 {
	font-family: constants.$fontTwo;
	font-size: 24px;
	margin-block-start: .5em;
	margin-block-end: .5em;
}

h3 {
	font-family: constants.$fontTwo;
	font-weight: 300;

}

p {
	font-family: constants.$fontTwo;
	font-size: 16px;
}



.mainContent {
	grid-area: body;
	box-sizing: border-box;
	height: calc(100vh - constants.$toolbarHeight);
	max-height: calc(100vh - constants.$toolbarHeight);
	overflow-y: auto;
}

@media (max-width: constants.$mobileCutoff) {
	#root {
		display: grid;
		grid-template-areas:
			"header"
			"body"
			"footer";

		grid-template-columns: 1fr;
		grid-template-rows: 50px 1fr auto;
	}

	.gutter {
		display: none;
	}
}