.shrinking {
	width: calc(100% - 4px);
	aspect-ratio: 1/1;
}

.spinning {
	width: 100%;
	aspect-ratio: 1/1;
	border: 2px solid #116466;
}

.iconWrapper {
	padding: 10px;
	border-radius: 50%;
	background-color: rgba(100, 100, 100, .5);
}

.icon {
	opacity: .5;
	stroke-width: 2px;
	fill: rgba(255, 255, 255, .7);
	stroke: rgba(255, 255, 255, .2);
	cursor: pointer;
	display: block;
}

.icon:hover {
	opacity: 1;
	stroke-width: 5px;
}

.overlay {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	width: 100%;
	height: 100%;
}

.overlay:hover {
	opacity: 1;
}