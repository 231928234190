@use "../themes/theme1.scss";

.reactExercises {
	display: flex;
	flex-direction: row;
	flex: 1;
	height: 100%;
	background-color: theme1.$lightest;

	.linkList {
		display: flex;
		flex-direction: column;
		width: 150px;
		height: 100%;
		padding-left: 20px;
		padding-top: 20px;
		font-size: 20px;
		box-sizing: border-box;
		border-right: 2px dashed black;

		&>a {
			margin-bottom: 5px;
		}
	}

	.content {
		padding: 10px;
		margin: 5px;
		//overflow-y: scroll;
		max-height: 100%;
		width: 100%;
	}

	.tags {
		span {
			text-decoration: underline;
			cursor: pointer;

			&:not(:last-child):after {
				content: ", ";
			}

		}
	}
}