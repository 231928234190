.circlesWidget {

	background-color: white;

	line {
		stroke-width: 2px;
		transition: all 2s;
		stroke-opacity: 1;

		&.fade {
			stroke-opacity: 0;
		}
	}
}