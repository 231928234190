// these top two are for a CSS-only effect.  It works, but only if the text doesn't wrap, so it's not sufficient for low-screen widths
// leaving it here just because its cool, though.

.typewriter {
	animation: typing 2.5s;
	white-space: nowrap;
	overflow: hidden;
	display: inline-block;
	font-family: monospace;
	max-width: fit-content;
}

@keyframes typing {
	from {
		width: 0%;
	}

	to {
		width: 100%;
	}
}

.cursor {
	content: '';
	border-right: 3px solid black;
	animation: blink-caret .75s step-end infinite alternate;
	vertical-align: top;
}

@keyframes blink-caret {

	from,
	to {
		border-color: transparent;
	}

	50% {
		border-color: black;
	}
}