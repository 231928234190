@use "../constants.scss";


.heroSection {
	width: 100%;

	.portrait {
		height: 300px;
		width: 300px;
		flex-shrink: 0;

		clip-path: polygon(0% 0%, 50% 0%, 100% 100%, 50% 100%);
		clip-path: polygon(0% 0%, 50% 0%, 100% 50%, 100% 100%, 50% 100%, 0% 50%);
		clip-path: circle(50% at 50% 50%);
	}
}

@media (min-width: constants.$mobileCutoff) {
	.heroSection {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.portrait {
		margin-left: 40px;
	}
}


@media (max-width: constants.$mobileCutoff) {
	.heroSection {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		p,
		h1,
		h3 {
			text-align: center;
		}
	}
}