@use "../_constants.scss";
@use "../themes/theme1.scss";

.widgets {
	display: flex;
	flex-direction: column;
	width: 100%;


	.cards {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		width: 100%;

		.card {
			display: flex;
			flex-direction: column;
			border-radius: 30px;
			background-color: theme1.$light;
			margin: 25px;
			padding: 15px;
			box-shadow: 2px 2px 6px;


			h2 {
				color: theme1.$darkest;
				font-weight: 300;
				font-family: constants.$fontOne;
			}

			.title {
				width: 100%;
				font-weight: 600;
				font-size: 30px;
				box-sizing: border-box;
			}

			>.content {
				border-radius: 10px;
				border: 4px solid theme1.$medium;
				overflow: hidden;
				margin-bottom: 20px;
			}
		}
	}
}

@media (min-width: constants.$mobileCutoff) {
	.widgets {

		.cards {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			width: 100%;

			.card {
				width: 300px;
			}
		}
	}
}


@media (max-width: constants.$mobileCutoff) {
	.widgets {
		.cards {
			display: flex;
			justify-content: center;
			align-items: center;

			.card {
				width: 300px;
				min-width: 300px;
			}
		}
	}
}