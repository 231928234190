.sandbox {
	.flexContainer {
		display: flex;
		flex-wrap: wrap;
	}

	.card {
		flex-direction: column;
		flex: 1;
		height: 100%;
	}

	.row {
		width: 100%;
		flex: 1;
	}

	.textArea {
		width: calc(100% - 40px);
		position: relative;
		height: 400px;
		margin: 20px;
		border-radius: 5px;
		box-sizing: border-box;
		border: 1px solid black;
	}

	th {
		text-align: left;
	}

	.dataTable {
		column-gap: 20px;

		td,
		th {
			padding: 0 10px;
		}
	}

	.centerContent {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.slideWindow {
		height: 200px;
		width: 200px;
		border: 2px solid red;
		position: relative;

		&:hover {
			.slideShowButton {
				//opacity: .8;
				background-color: lightgrey;
			}
		}
	}

	.slideShowImage {
		width: 200px;
	}

	.slideShow {
		height: 200px;
		position: relative;
		display: flex;
	}

	.slideShowButton {
		position: absolute;
		height: 50px;
		width: 30px;
		font-size: 40px;
		line-height: 50px;
		opacity: .6;
		top: calc(50% - 25px);
		bottom: calc(50% - 25px);
		font-weight: 800;
		text-align: center;
		cursor: pointer;
	}


}