@use "../constants.scss";
@use "../themes/theme1.scss";

.sectionWrapper {
	background-color: theme1.$lightest;
}

@media (min-width: constants.$mobileCutoff) {

	.sectionWrapper {
		width: 100%;
		display: flex;
		justify-content: center;
		min-height: calc(100vh - constants.$toolbarHeight);
		padding: 20px 20px;
		box-sizing: border-box;

		>.content {
			width: constants.$contentWidth;
			max-height: 100%;

			display: flex;
			align-items: center;
			flex-direction: column;
			padding: 0px 20px;
			padding-top: 20px;
			box-sizing: border-box;

			.innerContent {
				display: flex;
				align-items: center;
				flex: 1;
				width: 100%;
			}
		}
	}
}

@media (max-width: constants.$mobileCutoff) {
	.sectionWrapper {
		width: 100%;
		min-height: calc(100vh - constants.$toolbarHeight);
		padding: 20px 10px;
		box-sizing: border-box;
		display: flex;

		>.content {
			width: 100%;
			padding: 0px 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;

			.innerContent {
				display: flex;
				align-items: center;
				flex: 1;
			}
		}
	}
}