.frame {
	width: 100%;
	aspect-ratio: 1/1;
	position: relative;
	background-color: black;

	.orbitOutline {
		border-radius: 50%;
		border: 1px solid white;

	}

	.center {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateX(-50%) translateY(-50%);
	}

	.orbitStick {
		height: 0px;
		transform-origin: top left;
		position: absolute;
		left: 50%;
		top: 50%;

		animation: orbit 10s linear infinite;
	}

	.planetWrapper {
		position: absolute;
		left: 100%;
	}

	.planet {
		width: 100%;
		height: 100%;
		border-radius: 50%;
	}

	.sunlight {
		background-image: linear-gradient(to right, rgba(255, 255, 255, .3) 0 30%, rgba(0, 0, 0, .8) 60%);
	}
}


@keyframes orbit {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}