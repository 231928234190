@use "../_constants.scss";
@use "../themes/theme1.scss";

@media (min-width: constants.$mobileCutoff) {
	.projects {
		.cardList {
			display: flex;
			flex-direction: row;
			justify-content: center;
			flex-wrap: wrap;

			gap: 40px;
			margin-top: 40px;

			.card {
				width: 300px;
				display: flex;
				flex-direction: column;
				overflow: hidden;
				background-color: #F5F5F5;
			}
		}

		.cardSlideshow {
			display: none;
		}
	}
}


@media (max-width: constants.$mobileCutoff) {

	.projects {
		.cardList {
			display: none;
			// display: flex;
			// flex-direction: column;
			// justify-content: center;
			// align-items: center;
		}

		.cardSlideshow {

			.card {
				display: flex;
				flex-direction: column;
				width: 300px;
				overflow: hidden;
				background-color: #F5F5F5;
				height: 100%;

				.cardInnerContents {
					border-radius: 16px;
					display: flex;
					flex-direction: column;
					width: 300px;
					overflow: hidden;
					background-color: #F5F5F5;
					height: 100%;

				}
			}
		}
	}
}

.projects {
	width: 100%;

	.card {

		border-radius: 16px;
		box-shadow: 2px 2px 6px;

		h2 {
			color: theme1.$darkest;
			font-weight: 300;
			font-family: constants.$fontOne;
		}

		&:hover {
			transform: scale(1.05);
		}

		img {
			object-fit: cover;
			height: 200px;
			border: none;
			cursor: pointer;
			border-bottom: 1px solid gray;
		}

		.content {
			padding: 15px;
			display: flex;
			flex-direction: column;
			box-sizing: border-box;
			align-items: flex-start;
			width: 100%;
			cursor: pointer;
			flex: 1;

			p {
				flex-grow: 1;
			}

			.line {
				height: 1px;
				width: 100%;
				border-bottom: 1px solid gray;
				margin: 10px 0px;
			}

			a {
				color: blue;
				text-decoration: none;

				&:hover {
					text-decoration: underline;
				}
			}

		}
	}
}