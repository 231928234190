.waterFilled {

	position: relative;
	overflow: hidden;
	display: flex;
	background-color: inherit;
	width: 100%;


	.waterLayer {
		background-color: rgba(40, 60, 255, .5);
		width: 100%;
	}

	.centerWater {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		background-color: transparent;
	}

	.water {
		min-width: 250%;
		min-height: 250%;
		border-radius: 33%;
		aspect-ratio: 1/1;
		position: absolute;
		background-color: inherit;
		animation: spin 200s linear infinite;
	}

	@keyframes spin {
		0% {
			bottom: -20%;
		}

		50% {
			transform: rotate(4000deg);
			bottom: 120%;
		}

		100% {
			bottom: -20%;
		}
	}
}