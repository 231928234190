@use "../_constants.scss";
@use "../themes/theme1.scss";


.linksContainer {
	background-color: theme1.$lightest;
	position: sticky;
	top: 0;
	height: constants.$toolbarHeight;

	display: flex;
	justify-content: center;
	box-shadow: 0px 1px 2px theme1.$medium;
}

.linksContent {
	display: flex;
	flex-direction: row;
	padding: 0 40px;
	box-sizing: border-box;
	height: 100%;
}

.logoWrapper {
	flex: 1;
}

.logo {
	color: theme1.$dark;
	height: 100%;

	&:not(:hover) {
		transform: rotate(180deg) translateX(25%);
	}

	&:hover {
		animation: swim 2s linear infinite;
	}
}

@keyframes swim {
	0% {
		transform: rotate(180deg) translateX(25%);
	}

	25% {
		transform: rotate(179deg) translateY(-2px) translateX(20%);
	}

	50% {
		transform: rotate(180deg) translateX(15%);
	}

	75% {
		transform: rotate(181deg) translateY(1px) translateX(20%);
	}

	100% {
		transform: rotate(180deg) translateX(25%);
	}

}

.linkEntry {
	color: theme1.$dark;
	font-family: constants.$fontTwo;
}

.linkEntry.active::before {
	content: '> ';
	font-size: 20px;
	font-weight: 600;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.logoLine {
	fill: theme1.$medium;
}

@media (min-width: constants.$mobileCutoff) {
	.linksContainer {
		grid-area: header;
		box-sizing: border-box;
		transition: transform .5s;

		.linksContent {
			width: constants.$mobileCutoff;
		}

		.linkToolbar {
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 2px;
			overflow-x: hidden;
			height: 100%;
		}

		.hamburgerLinks {
			display: none;
		}

		.footer {
			grid-area: footer;
		}

		.linkEntry {
			flex: 0 1 auto;
			font-size: 20px;
			height: 30px;
			line-height: 30px;
			font-weight: 600;
			text-align: center;
			white-space: nowrap;
			padding: 0px 20px;
			text-decoration: none;

			&:not(:last-child) {
				border-right: 2px solid theme1.$medium;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		.dropdownContainer {
			display: none;
		}
	}
}

@media (max-width: constants.$mobileCutoff) {
	.linksContainer {
		grid-area: header;
		box-sizing: border-box;
		transition: transform .5s;
		display: flex;
		flex-direction: column;
		width: 100%;

		.dropdownContainer {
			background-color: theme1.$light;
			position: absolute;
			width: 100%;
			top: 100%;
			box-sizing: border-box;
			padding: 0px;
			box-shadow: 0px 1px 1px theme1.$light;
			max-height: 500px;
			transition: all .2s;

			display: flex;
			flex-direction: column;
			overflow: hidden;

			&.hidden {
				max-height: 0px;

				.linkEntry {
					height: 0px;
					padding: 0px;
					font-size: 0px;
				}
			}

			.linkEntry {
				flex: 0 1 auto;
				height: 30px;
				padding: 5px 0px;
				font-size: 24px;
				transition: all .2s;
				line-height: 30px;
				font-weight: 600;
				text-align: center;
				border-radius: 2px;
				white-space: nowrap;
				text-decoration: none;
				border-top: 1px solid white;

				&:hover {
					text-decoration: underline;
				}
			}
		}

		.linksContent {
			width: 100%;
		}

		.linkToolbar {
			display: none;
		}

		.hamburgerLinks {
			display: flex;
			align-items: center;
			align-self: flex-end;
			justify-self: end;
			flex-direction: row-reverse;
			height: 100%;
			width: 30px;
			position: relative;
			cursor: pointer;

			.hamburgerLine,
			.hamburgerLine::before,
			.hamburgerLine::after {
				width: 30px;
				height: 4px;
				border-radius: 50px;
				background-color: theme1.$dark;
				display: block;
				content: '';
			}

			.hamburgerLine,
			.hamburgerLine::before,
			.hamburgerLine::after {
				width: 30px;
				height: 4px;
				border-radius: 50px;
				background-color: theme1.$dark;
				display: block;
				content: '';
			}

			.hamburgerLine {
				&::before {
					margin-top: -8px;
				}

				&::after {
					margin-top: 12px;
				}
			}
		}
	}
}